// Overrides CoreUI & Bootstrap Variables

//
// Color system
//

$white:                           #fff;
$gray-100:                        #f8f9fa;
$gray-200:                        #ebeef1;
$gray-300:                        #d1d4d7;
$gray-400:                        #869fac;
$gray-500:                        #678898;
$gray-600:                        #55595c;
$gray-700:                        #3e515b;
$gray-800:                        #2a2c36;
$gray-900:                        #151b1e;
$black:                           #000;

$blue:                            #3a7243;
$indigo:                          #6610f2;
$purple:                          #6f42c1;
$pink:                            #e83e8c;
$red:                             #f86c6b;
$orange:                          #f8cb00;
$yellow:                          #ffc107;
$green:                           #4dbd74;
$teal:                            #20c997;
$cyan:                            #63c2de;


// Import default variables
@import "node_modules/@coreui/coreui-pro/scss/variables";

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-transitions:              true;
$enable-rounded:                  true;

// Body
//
// Settings for the `<body>` element.

$body-bg:                         #e4e5e6;

// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-base:                  0.875rem;

// Breadcrumbs

$breadcrumb-padding-x:            2rem;
$breadcrumb-bg:                   transparent;
$breadcrumb-margin-bottom:        0rem;

// Cards

$card-cap-bg:                     $gray-100;

// Dropdowns

$dropdown-padding-y:              0;
$dropdown-divider-bg:             $gray-100;

// Buttons

$btn-secondary-border:            $gray-300;

// Progress bars

$progress-bg:                     $gray-100;

// Tables

$table-bg-accent:                 $gray-100;
$table-bg-hover:                  $gray-100;

// Forms

$input-group-addon-bg:            $gray-200;
$input-border-color:              $gray-300;
$input-group-addon-border-color:  $gray-300;

// CoreUI overrides

$enable-sidebar-nav-rounded:        true;

// Navbar

$navbar-brand-width:                240px;
$navbar-brand-bg:                   #73818f;
$navbar-brand-border: (
    bottom: (
        size:                       0.2px,
        style:                      solid,
        color:                     lighten(#0E365B, 50%)
    )
);

$navbar-brand-minimized-bg:         $navbar-brand-bg;
$navbar-brand-minimized-border:     $navbar-brand-border;

// Sidebar

$sidebar-width:                     210px;
$sidebar-padding:                   0 0 0 0;
$sidebar-color:                     $gray-800;
$sidebar-bg: #5c6873;
$mobile-sidebar-width:              200px;

// Sidebar Navigation

$sidebar-nav-color:                 $white;
$sidebar-nav-title-padding-y:       .1rem;
$sidebar-nav-title-color:           $white;
$sidebar-nav-link-color:            $white;
$sidebar-nav-link-icon-color:       #fff;


$sidebar-nav-link-hover-bg:         #73818f;

$sidebar-nav-link-active-bg: #73818f;
$sidebar-nav-link-active-icon-color:#fff !important;

$sidebar-nav-dropdown-color:        $white;
$sidebar-nav-dropdown-bg:           $gray-500;
$sidebar-nav-dropdown-icon-color:   #fff !important;
$sidebar-nav-dropdown-borders:      4;

// Breadcrumb

$breadcrumb-margin:                 0;
$breadcrumb-borders:                0;

// Footer

$footer-bg:                         transparent;
$footer-color:                      $body-color;
$footer-borders:                    0;
