// Custom variables
@import "variables";

// Import styles with default layout.
@import "node_modules/@coreui/coreui-pro/scss/coreui";

// If you want to add something do it here
@import "custom";

.tableTotal {
  font-size: 15px;
}

.titlePage {
  font-size: 1.5em;
  font-weight: bold;
  padding-left: 20px;
  padding-bottom: 5px;
}

.titleCardHeader {
  font-size: 1.5em;
  font-weight: bold;
  padding-bottom: 5px;
}

.rowCenter {
  margin-top: 30px;
  height: 50px;
  justify-content: center;
}

.buttonNewRegister {
  justify-content: flex-end;
  margin-left: 3px;
  margin-right: 3px;
}
.filterList {
  margin-left: 15px;
  margin-right: 15px;
}

.cardStyle {
  border: 0px;
}

.cardHeaderStyle {
  border: 0px;
  background-color: transparent;
}

.errorMessage {
  color: red;
}
